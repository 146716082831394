import React from 'react';
import { Flex, Box } from 'rebass';
import DhfLoading from './DhfLoading';

const sizes = {
  mini: '12px',
  small: '17px',
  medium: '25px',
  large: '30px',
  extra: '35px',
};

const BrandLoading = {
  DHF: DhfLoading,
}[process.env.BRAND_NAME || 'DHF'];

const Loading = ({ width, size, minHeight, className, color, ...props }) => {
  size = sizes[size] || size || width;
  return (
    <Flex
      className={className}
      alignItems="center"
      justifyContent="center"
      css={{
        position: 'relative',
        height: '100%',
        minHeight: minHeight != null ? minHeight : '100px',
        ...props,
      }}
    >
      <Box className="icon-box" css={{ overflow: 'hidden', padding: '10px' }}>
        <BrandLoading
          color={color}
          width={size ? size : '30px'}
          height={size ? size : '30px'}
        />
      </Box>
    </Flex>
  );
};

export default Loading;
