import React, { useCallback, useContext } from 'react';

import { merge } from 'lodash';
import { path } from 'lodash/fp';
import base from './base';
import dhf from './dhf';
import useContactDetails from '~lib/hooks/sanity/useContactDetails';

const brandName = (process.env.BRAND_NAME || 'DHF').toLowerCase();

const getLocale = data => {
  const brandLocale = {
    dhf,
  }[brandName];
  return merge(base(data), brandLocale(data));
};

const getLocaleString = (key, data) => {
  const locale = getLocale(data);
  const stringValue = path(key)(locale);
  if (!stringValue) {
    console.warn('Locale string ', key, 'does not exist for brand', brandName);
    return '';
  } else {
    return stringValue;
  }
};

const LocaleContext = React.createContext({
  locale: {},
  getLocaleString,
});

export const LocaleProvider = ({ children }) => {
  const contactDetails = useContactDetails();
  const getLocalStringProxy = useCallback(
    key => getLocaleString(key, { contactDetails }),
    [contactDetails]
  );

  return (
    <LocaleContext.Provider
      value={{
        getLocaleString: getLocalStringProxy,
      }}
    >
      {children}
    </LocaleContext.Provider>
  );
};

export const useLocale = () => {
  return useContext(LocaleContext);
};
